.sale-btn{
    border: 1px solid #987646 !important
}

.connect-btn{
    padding: 10px;
    width: 140px;
    font-family: outfit;
    margin-left: 5px;
    border: 1px solid #987646 !important
}

.connect-btn-pad{
    padding: 12px 10px;
}

.font-200{
    font-weight: 200 !important;
}